<template lang="pug">
    article#partners
        img(:src="partners[galeriaAtivo].bg.img", :alt="partners[galeriaAtivo].titulo", :style="partners[galeriaAtivo].bg.style").bgAGt
        div.wrapper.flex
            DescricaoAGT(:logo="descricaoLogo", :conteudo="descricaoConteudo")
            GaleriaAGT(:galeria="partners", :galeriaAtivo.sync="galeriaAtivo")
</template>

<script>
import DescricaoAGT from '@/components/DescricaoAGt/DescricaoAGt.vue'
import GaleriaAGT from '@/components/GaleriaAGt/GaleriaAGt.vue'

export default {
    components: {
        DescricaoAGT,
        GaleriaAGT
    },
    data() {
        return {
            galeriaAtivo: 0,
            descricaoLogo: {
                img: require('@/assets/images/logo/logo_gtcompany_partners.png'), 
                alt: 'Gt Company Partners'
            },
            descricaoConteudo: {
                titulo: 'Negócios em parceria', 
                texto: 'A GT Partners é mais do que grandes investimentos, são parcerias em que o Dr. Geninho Thomé pode contribuir com seu capital intelectual. São parcerias que geram grandes projetos, criam ideias e o desafio de chegar mais longe para causar impacto positivo nas pessoas.'
            },
            partners: [
                {
                    titulo: "YACHTHOUSE BY PININFARINA",
                    subtitulo: "AS TORRES MAIS ALTAS DA AMÉRICA LATINA",
                    texto: "O Yachthouse by Pininfarina, megaempreendimento de luxo, possui incríveis 280 metros de altura. Um marco da engenharia brasileira, viabilizado pela parceria entre a Pasqualotto e a GT Company.",
                    img: require('@/assets/images/partners/yachhouse-mobile.jpg'),
                    bg: {
                        img: require('@/assets/images/partners/yachhouse-mobile.jpg'),
                        style: {}
                    }
                },
                {
                    titulo: "VITRA BY PININFARINA",
                    subtitulo: "LUXO E DESIGN",
                    texto: "Premiado pelo American Architecture Awards e pelo German Design Award, o Vitra by Pininfarina lembra uma pista de automobilismo, com linhas dinâmicas, modernas e sofisticadas que transmitem a sensação de movimento.",
                    img: require('@/assets/images/partners/vitra-mobile.jpg'),
                    bg: {
                        img: require('@/assets/images/partners/vitra-mobile.jpg'),
                        style: {}
                    }
                },
                {
                    titulo: "LA CITTÀ BY PININFARINA",
                    subtitulo: "IMPONENTE E INOVADOR",
                    texto: "Um empreendimento inovador que traz uma nova linguagem visual, estrutural e conceitual. Seu projeto foi elaborado para oferecer o que há de melhor e mais atual no mercado da construção civil de alto padrão mundial.",
                    img: require('@/assets/images/partners/lacitta-mobile.jpg'),
                    bg: {
                        img: require('@/assets/images/partners/lacitta-mobile.jpg'),
                        style: {}
                    }
                },
            ]
        }
    },
}
</script>

<style lang="stylus" scoped src="./Partners.styl"></style>